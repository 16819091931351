export async function subscribeToMailchimp(userEmail, status, userName, userLastName) {
  try {
    const res = await fetch(
      `/.netlify/functions/subscribeToMailchimp?email=${userEmail}&status=${status}&name=${userName}&lastname=${userLastName}`,
      {
        method: 'post'
      }
    )
    const formattedResponse = res.json()
    return formattedResponse
  } catch (error) {
    throw new Error(error)
  }
}
