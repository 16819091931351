<template lang="pug">
footer(v-if='footerData')
  .footer-wrapper
    .footer-infos
      .footer-column
        prismic-rich-text(:field='footerData.footer_title')
        common-button.footer-infos__main-btn(label='Nos programmes', variant='white', :path='{ name: "Courses" }')
      .footer-column.newsletter-column
        div(v-if='subscribeStatus === "success"')
          p.success-msg Votre inscription à la newsletter à été prise en compte !
        div(v-else-if='subscribeStatus === "alreadyExists"')
          p.success-msg Vous êtes déjà inscrit, merci de votre intérêt
        template(v-else)
          span.newsletter-title {{ footerData.receive_news[0].text }}
          form(@submit.prevent='processSubscription')
            input.newsletter-input(type='email', placeholder='Adresse email', v-model='userEmail')
            button.newsletter-button(type='submit', aria-label='Souscrire')
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12L10 8L6 4" stroke="#001A49" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          p.fail-msg(v-if='subscribeStatus === "fail"') Une erreur s'est produite, veuillez réessayer.

    .footer-links.footer-column
      ul 
        router-link(v-for='elt in navElements', :to='{ name: elt.name }', :key='"route:" + elt.name') {{ elt.meta.label }}
  img(src='@/assets/badge.png', :style='{ height: "60px" }', alt='Badge pcc')
  span.footer-text {{ footerData.footer_text[0].text }}
</template>

<script>
import getNavElements from '@/utils/NavElements'
import { subscribeToMailchimp } from '@/services/mailchimpService'

export default {
  name: 'Footer',
  components: {
    CommonButton: () => import('@/components/CommonButton')
  },
  data() {
    return {
      footerData: null,
      userEmail: '',
      subscribeStatus: null
    }
  },
  computed: {
    navElements() {
      return getNavElements(this.$router.options.routes)
    }
  },
  created() {
    this.getFooterData()
  },
  methods: {
    async getFooterData() {
      const result = await this.$prismic.client.getSingle('footer')
      this.footerData = result.data
    },
    async processSubscription() {
      await subscribeToMailchimp(this.userEmail, 'subscribed')
        .then((res) => {
          if (res.status === 400 && res.title === 'Member Exists') {
            this.subscribeStatus = 'alreadyExists'
          } else {
            this.subscribeStatus = 'success'
          }
          console.log(res)
        })
        .catch((err) => {
          this.subscribeStatus = 'fail'
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/colors';
@import '@/styles/mixins';

footer {
  padding: $mainHorizontalPadding;
  min-height: $footerHeight;
  background: $darkBlue;
  color: white;

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  @include medium-screen {
    padding: 4rem $mainHorizontalPaddingMedium;
  }

  @include large-screen {
    padding: 4rem $mainHorizontalPaddingLarge;
  }

  .footer-infos {
    display: flex;
    flex-direction: column;

    .footer-column {
      margin-right: 3rem;
      justify-content: space-around;
    }

    @include medium-screen {
      flex-direction: row;

      .footer-column {
        margin-right: 5rem;
      }
    }

    &__main-btn {
      margin-bottom: 1rem;

      @include medium-screen {
        display: block;
      }
    }

    &::v-deep {
      h4 {
        margin-bottom: 1rem;

        @include medium-screen {
          font-size: 1.4rem;
          margin-bottom: 2rem;
        }
      }
      p {
        line-height: 1.4rem;
        margin-bottom: 1rem;
      }
    }

    .newsletter-input {
      background: transparent;
      border: none;
      border-bottom: 1px solid $white;
      color: $white;
      padding: 1rem 1rem 1rem 0;
      width: 150px;

      &:focus {
        outline: none;
      }

      @include medium-screen {
        flex-direction: row;
        width: auto;
      }
    }
    .newsletter-button {
      background: $orange;
      border: none;
      border-radius: 5px 5px 0 0;
      color: $darkBlue;
      padding: 1rem 0 1rem 0;
      width: 40px;
      cursor: pointer;
      font-weight: bold;
      font-family: 'Hind Vadodara', sans-serif;

      &:focus {
        outline: none;
      }

      @include medium-screen {
        flex-direction: row;
      }
    }
  }

  .footer-links {
    ul {
      display: flex;
      flex-direction: column;

      a {
        color: white;
        text-decoration: none;
        font-family: 'DM sans', sans-serif;
        margin-bottom: 2rem;
        text-align: end;
      }
    }
  }
}

.footer-text {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 10px;
}

.newsletter-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 700;
  margin-top: auto;

  @include medium-screen {
    font-size: 1.5rem;
    margin-top: 0;
  }

  .newsletter-title {
    margin-bottom: 1rem;
    display: block;
  }
}

.success-msg {
  font-size: 1rem;
}
.fail-msg {
  font-size: 1rem;
}
.alreadyExists-msg {
  font-size: 1rem;
}
</style>
